import { ModalContext } from '@components/Modal/context/ModalContext'
import { useContext } from 'react'

const DepositAnnouncementModal = () => {
  const { handleToggleModal } = useContext(ModalContext)

  return (
    <div className='max-w-[375px] sm:max-w-[100%] lg:max-w-[800px] bg-gray-800 max-h-[70vh] overflow-y-auto no-scrollbar mb-3'>
      <div className='border border-secondary rounded-md  mt-10  mx-2 mb-1 sm:mx-3 sm:mb-3 bg-secondary'>
        <p className='text-center text-sm  sm:text-xl mb-5 font-semibold mt-5'>
          You need to transfer an <b>EXACTLY AMOUNT</b>
          <br />
          in digits as shown for the system to verify your transaction
        </p>

        <picture className='flex justify-center mb-7 '>
          <source
            srcSet='/depositTutorial/deposit_amount_total.webp'
            type='image/webp'
          />
          <img
            src='/depositTutorial/deposit_amount_total.webp'
            alt='deposit_amount_total'
            title='deposit_amount_total'
            loading='lazy'
            className='w-full sm:w-[90%] h-auto rounded-md'
          />
        </picture>
      </div>

      <p className='text-center mb-1 sm:mb-3 font-bold'>And</p>

      <div className='border border-yellow-500  rounded-md mb-5 sm:mb-10 mx-2 sm:mx-5'>
        <p className='text-center text-sm  sm:text-xl mb-5 font-semibold mt-5'>
          <span>Please add an extra 1 USDT for the network fee</span>
          <br />
          <span>
            or click &quot;Change withdrawal amount to received amount&quot;
          </span>
          <br />
          <span> before you proceed</span>
        </p>

        <picture className='flex justify-center mb-7 '>
          <source
            srcSet='/depositTutorial/binance_fee_amount.webp'
            type='image/webp'
          />
          <img
            src='/depositTutorial/binance_fee_amount.webp'
            alt='binance_fee'
            title='binance_fee'
            loading='lazy'
            className='w-full sm:w-[90%] h-auto rounded-md'
          />
        </picture>
      </div>

      <div className='flex justify-end  mr-1 sm:mr-5'>
        <button
          onClick={() => handleToggleModal()}
          className='rounded-[4px] flex space-x-1.5 px-[12px] py-[12px] items-center justify-center whitespace-pre cursor-pointer h-[40px] min-w-80px flex-shrink-0 bg-primary'
        >
          Got it
        </button>
      </div>
    </div>
  )
}
export default DepositAnnouncementModal
