import { CheckCircleOutlinedIcon } from '@components/Icons/Icons'
import { UserTier } from '@models/User/UserTier'
import { mappedTierIdToIcon } from '@constant/tierIconMapper'
import { useResponsive } from '@hooks/useResponsive'

interface TierStepProps {
  isActiveTier: boolean
  userTier: UserTier
}

function TierStep({ userTier, isActiveTier }: TierStepProps) {
  const { isMobile } = useResponsive()
  return (
    <div className='flex flex-row min-h-[104px] px-5 py-3 border border-gray-300 rounded-[10px] space-x-4'>
      {isActiveTier ? (
        <CheckCircleOutlinedIcon className='flex-none' height='27' width='27' />
      ) : (
        <div className='h-[27px] w-[27px] rounded-full border border-gray-400 bg-white' />
      )}
      <div className='flex flex-col space-y-[6px] flex-grow'>
        <div className='inline-flex text-base font-bold'>
          <span className='mr-1'>{userTier.name.toUpperCase()}</span>
          {Array.from({ length: userTier.id }, (_, index) =>
            mappedTierIdToIcon({ tierId: userTier.id, key: index.toString() })
          )}
        </div>
        <div className='text-xs'>
          receiving:{' '}
          <span className='font-bold'>
            {Math.round(userTier.membershipFee * 100 * 100) / 100}% of
            registration fee
          </span>
        </div>
        <div className='text-xs'>
          receiving:{' '}
          <span className='font-bold'>
            {Math.round((userTier.serviceCharge * 100 * 100) / 100)}% of profit
            sharing
          </span>
        </div>
      </div>
    </div>
  )
}

export default TierStep
