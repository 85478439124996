import PrimaryButton from '@components/buttons/PrimaryButton'
import InputField from '@components/form/InputField/InputField'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { CopyIcon, Info2Icon } from '@components/Icons/Icons'
import { DepositStepEnum } from '@interfaces/DepositStepEnum'
import Loading from '@components/utils/Loading'
import { QRCode } from 'react-qrcode-logo'
import tether from '@assets/images/tether.png'
import { DepositAmountProps } from './interface'
import wthDepositAmount from './wthDepositAmount'

const schema = Yup.object().shape({
  amount: Yup.number()
    .min(1)
    .required()
    .integer('Please use integers instead decimals.'),
})

function DepositAmount({
  form,
  handleDepositFormSubmit,
  currentStep,
  handleStep,
  countingTime,
  handleCopy,
  timeRemaining,
  isLoading,
  isCreateMutateLoading,
  data,
  isError,
  error,
  onHandleExpireTime,
}: DepositAmountProps) {
  if (isLoading || isCreateMutateLoading) {
    return <Loading text='Loading...' />
  }

  if (isError) {
    return <p>{error}</p>
  }

  return (
    <>
      {currentStep === DepositStepEnum.INPUT_AMOUNT && (
        <div className={`flex flex-col items-center font-medium mb-[68px] `}>
          <Formik
            initialValues={form}
            onSubmit={handleDepositFormSubmit}
            validationSchema={schema}
            enableReinitialize
          >
            {() => (
              <Form className='mt-[16px] flex flex-col w-full'>
                <InputField
                  name='amount'
                  type='number'
                  label='Amount (USDT)'
                  placeholder='Enter amount'
                  required
                />

                <PrimaryButton
                  type='submit'
                  className='w-full rounded-[18px] py-[8px] mt-[20px]'
                >
                  <p className='text-lg font-bold'>Next</p>
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {currentStep === DepositStepEnum.SEND_USDT && (
        <div className='flex flex-col  font-medium mb-[68px] mt-5'>
          <div className='flex flex-col p-3 border-1 border-gray-600 rounded-[5px] bg-gray-800 mb-[16px] w-full'>
            {/* top */}
            <div className='flex justify-between items-center w-full'>
              <div>
                <h2 className='font-bold mb-1'>
                  Send USDT by TRON network (TRC20)
                </h2>
                <p className='text-xs text-gray-300 max-w-[470px]'>
                  Open your crypto wallet and scan the QR code, or copy the USDT
                  address below to make a payment
                </p>
              </div>

              <div className='text-gray-200 mt-2'>
                <CountdownCircleTimer
                  isPlaying
                  duration={timeRemaining || 3600}
                  size={60}
                  strokeWidth={3}
                  isSmoothColorTransition={false}
                  colors={['#21BB8E', '#21BB8E', '#21BB8E', '#21BB8E']}
                  colorsTime={[3600, 3550, 600, 300]}
                  onComplete={() => {
                    onHandleExpireTime()
                    return {
                      shouldRepeat: false,
                      delay: 1,
                    }
                  }}
                >
                  {countingTime}
                </CountdownCircleTimer>
              </div>
            </div>

            <div className='w-full h-[1px] bg-gray-600 my-3' />
            <div className='w-full flex  flex-col justify-center items-center mt-3'>
              <div className='mb-3'>
                {data && data.masterWalletAddress && (
                  <QRCode
                    value={data.masterWalletAddress}
                    size={185}
                    logoImage={tether}
                    logoHeight={40}
                    logoWidth={40}
                    logoOpacity={1}
                    eyeRadius={10}
                    id='QR'
                  />
                )}
              </div>
              <p className='inline-flex justify-center text-xs bg-gray-600 p-2 rounded-[5px] my-3 max-w-[600px] px-4 py-2'>
                <span className='mr-2'>
                  <Info2Icon className='text-gray-600 h-[16px] w-[16px]' />{' '}
                </span>
                <span>
                  {` Only send USDT to TRON network (TRC20). Do not send USDT from another network, and please transfer the exact amount shown in the "Total Amount" section `}
                </span>
              </p>
            </div>

            <div className='w-full h-[1px] bg-gray-600 my-3' />

            <div className='flex justify-between w-full'>
              <div>
                <div className='text-xs font-medium text-gray-300 mb-1'>
                  Wallet Address
                </div>
                <p className='text-xs md:text-base'>
                  {data && data.masterWalletAddress}
                </p>
              </div>

              {data && (
                <div
                  className='text-gray-200 mt-2 cursor-pointer'
                  onClick={() =>
                    handleCopy(
                      data.masterWalletAddress,
                      'Wallet Address copied.'
                    )
                  }
                >
                  <CopyIcon />
                </div>
              )}
            </div>

            <div className='w-full h-[1px] bg-gray-600 my-3' />

            <div className='flex justify-between w-full'>
              <div>
                <div className='text-xs font-medium text-gray-300'>
                  Total Amount
                </div>
                <p className='text-lg font-bold text-primary'>
                  {data && data.amount}
                </p>
                <p className='text-xs font-medium text-gray-300'>
                  {` You need to transfer an amount of EXACTLY ${
                    data && data.amount
                  } for the system to verify your transaction.`}
                </p>
              </div>

              {data && (
                <div
                  className='text-gray-200 mt-2 cursor-pointer'
                  onClick={() =>
                    handleCopy(`${data.amount}`, 'Total amount copied.')
                  }
                >
                  <CopyIcon />
                </div>
              )}
            </div>
          </div>

          <div className='mb-5 flex justify-end' onClick={() => handleStep(1)}>
            <button className='flex justify-center items-center'>
              <span className='text-md border-b'>Go back</span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const WrappedComponent = wthDepositAmount(DepositAmount)
export default WrappedComponent
